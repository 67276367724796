import { render, staticRenderFns } from "./vue-html2pdf.vue?vue&type=template&id=211aca94&scoped=true"
import script from "./vue-html2pdf.vue?vue&type=script&lang=js"
export * from "./vue-html2pdf.vue?vue&type=script&lang=js"
import style0 from "./vue-html2pdf.vue?vue&type=style&index=0&id=211aca94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "211aca94",
  null
  
)

export default component.exports